var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"is-flex is-align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.totalComplete)+"/"+_vm._s(_vm.total))]),_c('div',_vm._b({staticClass:"progress-circle is-success ml-2",style:({
        'width': _vm.width,
        'height': _vm.height,
        'border-color': _vm.backgroundColor
    }),attrs:{"max":"100"}},'div',{ value: _vm.value },false),[_c('span',{staticClass:"progress-inner",style:({
        'background-color': _vm.backgroundColor
    })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }