<template>
    <div class="is-flex is-align-items-center" v-tooltip="tooltip">
        <span>{{ totalComplete }}/{{ total }}</span>
        <div class="progress-circle is-success ml-2" :style="{
            'width': width,
            'height': height,
            'border-color': backgroundColor
        }" v-bind="{ value }" max="100">
        <span class="progress-inner" :style="{
            'background-color': backgroundColor
        }"></span>
        </div>
    </div>
</template>
<script>
export default {
    
    props: {
        tooltip: String,
        total: Number,
        totalComplete: Number,
        width: {
            type: String,
            default: '25px'
        },
        height: {
            type: String,
            default: '25px'
        },
        backgroundColor: String
    },

    computed: {
        value() {
            if(!this.total) return 0

            return ((this.totalComplete / this.total) * 100).toFixed(0)
        }
    }

}
</script>